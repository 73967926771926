// eslint-disable-next-line no-redeclare,no-unused-vars
function addHistoryToMenu(add) {
  var li = $('<li data-history="' + add.ident + '"></li>').prependTo(
    menuHistory
  );
  var item = $(
    '<a href="javascript:void(0)" class="sub-link link-light fw-normal" >' +
      add.title +
      '</a>'
  ).appendTo(li);
  item.on(click, function () {
    ajax(add);
    li.remove();
    var i = arraySearchAssoc(historyStack, 'ident', add.ident);
    if (i !== false) historyStack.splice(i, 1);
  });
}
